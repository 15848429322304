var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('h3',[_vm._v(_vm._s(_vm.$tc("competition.competition", 2)))])]),(_vm.$store.state.editMode && _vm.createPermission)?_c('b-col',[_c('b-button',{staticClass:"btn-orange float-right",attrs:{"to":{
          name: 'competition-create',
          params: { event_id: _vm.$route.params.event_id }
        },"variant":"light"}},[_vm._v(_vm._s(_vm.$tc("competition.add", 2))+" ")])],1):_vm._e()],1),_c('b-row',[_c('b-col',[(_vm.errors.main)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('ul',_vm._l((_vm.errors.main),function(e){return _c('li',{key:e},[_vm._v(_vm._s(e))])}),0)]):_vm._e()],1)],1),_c('b-row',[_c('b-col',[(_vm.competitions.count > _vm.competitions.limit)?_c('b-pagination',{attrs:{"total-rows":_vm.competitions.count,"per-page":_vm.competitions.limit,"align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),_c('b-table',{attrs:{"fields":_vm.listFields,"items":_vm.competitions.results,"hover":""},on:{"row-clicked":_vm.linkTo,"row-middle-clicked":_vm.linkToNewTab},scopedSlots:_vm._u([{key:"cell(date)",fn:function(data){return [(data.item.date_start === data.item.date_end)?_c('div',[_vm._v(" "+_vm._s(data.item.date_start)+" ")]):_c('div',[_vm._v(" "+_vm._s(data.item.date_start)+" - "+_vm._s(data.item.date_end)+" ")]),(_vm.$store.state.editMode && _vm.$store.state.user.is_staff)?_c('b-button',{staticClass:"btn-orange btn-sm",attrs:{"to":{
              name: 'admin',
              params: {
                model_name: 'competition',
                object_id: data.item.id
              }
            },"variant":"light"}},[_vm._v(_vm._s(_vm.$t("admin"))+" ")]):_vm._e()]}}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingCompetitions),expression:"loadingCompetitions"}]},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }