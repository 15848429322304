import { render, staticRenderFns } from "./CompetitionResults.vue?vue&type=template&id=9ddfd3fe&scoped=true&"
import script from "./CompetitionResults.vue?vue&type=script&lang=js&"
export * from "./CompetitionResults.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ddfd3fe",
  null
  
)

export default component.exports